<template>
<div class="interact-record">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="interact-record-cont">
    <div class="interact-record-cont-tit">{{ interact.name }}</div>
    <el-table
        :data="tableData"
        border
        class="table"
    >
      <el-table-column
          prop="id"
          label="ID">
      </el-table-column>
      <el-table-column
          prop="name"
          label="发起时间"
      >
        <template slot-scope="scope">
          {{util.timeFormatter(new Date(+scope.row.created_at*1000), 'yyyy-MM-dd hh:mm')}}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="答题时长">
        <template slot-scope="scope">
          {{scope.row.timelong == '90' ? '不限时' : timelongs[scope.row.timelong]}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="参与人数">
        <template slot-scope="scope">
          {{scope.row.train && scope.row.train.join_user_count}}
        </template>
      </el-table-column>
      <el-table-column
          prop="data_count"
          label="互动班级">
        <template slot-scope="scope">
          {{scope.row.group && scope.row.group.name}}
        </template>
      </el-table-column>
      <el-table-column
          prop="wait_check_user_count"
          label="待批阅人数">
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态">
        <template slot-scope="scope">
          {{interactTaskStatuses[scope.row.status]}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="goDetail(scope.row)">答题详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js';
export default {
  name: "interactRecord",
  data(){
    return {
      util:util,
      tableData:[],
      page:1,
      pageSize:10,
      total:0,
      interactId:'',
      timelongs:{},
      interactTaskStatuses:{},
      interact:{},
    }
  },
  created(){
    this.interactId = this.$route.query.id || '';
    this.getList();
    this.getCourseForm();
  },
  methods:{
    getList(){
      let params = {
        interact_id:this.interactId,
        page:this.page,
        pageSize:this.pageSize
      }
      this.api.course.courseInteractTaskList(params).then((res)=>{
        this.tableData = res.list;
        this.interact = res.interact;
        this.total = Number(res.count);
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.page = 1;
      this.pageSize = pageSize;
      this.getList();
    },
    goDetail(data){
      this.$router.push({
        path:'/course/interact/detail',
        query:{
          id:data.id
        },
      })
    },
    getCourseForm(){
      this.api.course.courseForm().then((res)=>{
        this.timelongs = res.timelongs;
        this.interactTaskStatuses = res.interact_task_statuses
      })
    },
  }
}
</script>

<style scoped lang="scss">
.interact-record{
  margin: 20px;
  .interact-record-cont{
    padding: 20px;
    background-color: #FFFFFF;
    .interact-record-cont-tit{
      margin-bottom: 20px;
      height: 34px;
      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .page{
      margin-top: 20px;
      text-align: right;
    }
  }
}
</style>
